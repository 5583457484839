body {
    padding: 0;
    margin: 0;
    background: #263238;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
}

p {
    margin: 0;
}

.bigblue {
    color: DodgerBlue;
    padding: 40px;
    font-family: Arial;
    text-align: center;
  }

.headers {
    position: absolute;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 28px;
    /*padding-top: 16px;*/
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    height: 60px;
}

.headers>.header {
    display: inline-block;
    text-align: center;
}

.flipper {
    -webkit-perspective: 1200px;
    perspective: 1200px;
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    overflow: visible;
}

.flipping {
    transition: all 1s ease-in-out;
    z-index: 100;
}


.flipper.flipped {
    -webkit-transform: translate3d(0, 0, 0) !important; /* upper left corner overriding absolute positioning */
    transform: translate3d(0, 0, 0) !important;
    width: 100% !important; /* full screen overriding absolute width */
    height: 100% !important;  /* full screen overriding absolute height */
    z-index: 100;
}

.flipper.flipped .card {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

.card {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: all 1s ease-in-out;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 53px;
}

.card > .front,
.card > .back {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 4px;
    left: 4px;
    bottom: 4px;
    right: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: solid 4px #FFFFFF;
    border-radius: 4px;
}

.card > .front {
    -webkit-transform: rotateY(0deg) !important;
    transform: rotateY(0deg) !important;
    background-color: #61DAFB;
    color: #FFFFFF;
    font-size: 54px;
    z-index: 2;
}

.card > .back {
    -webkit-transform: rotateY(180deg) !important;
    transform: rotateY(180deg) !important;
    background-color: #009CDF;
    color: #FFFFFF;
    font-size: 36px;
    font-weight: 300;
    padding: 0 50px;
}

.flipper.done .front > .points {
    display: none;
}


.flipper.flipped .back {
    font-size: 60px;
}

.front>img {
    width: 80px;
}

.back img {
    max-width: 80%;
    max-height: 80%;
}

.back>img {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 120px;
}

ul, ol {
    text-align: left;
    display: inline-block;
}

li {
    margin-top: 8px;
}


ol {
    list-style-type: upper-alpha;
}

code {
    margin-top: 28px;
    display: inline-block;
    font-size: 38px;
    overflow: auto;
    text-align: left;
    font-family: 'Source Code Pro', monospace;
    text-align: left;
}

@media screen and (min-width : 641px) and (max-width : 1024px) {

    .headers {
        font-size: 20px;
    }

    .card > .front {
        font-size: 36px;
    }

    .card > .back {
        font-size: 15px;
        padding: 0 25px;
    }

    .flipper.flipped .back {
        font-size: 36px;
    }

    .card > .front img {
        width: 60px;
    }

    .card > .back > img {
        width: 48px;
    }

    code {
        margin-top: 24px;
        font-size: 24px;
    }

    .card > .front,
    .card > .back {
        top: 3px;
        left: 3px;
        bottom: 3px;
        right: 3px;
    }


}

@media screen and (min-width : 0) and (max-width : 640px) {

    .headers {
        font-size: 14px;
        height: 32px;
    }

    .card > .front {
        font-size: 24px;
    }

    .card > .back {
        font-size: 14px;
        padding: 0 14px;
    }

    .flipper.flipped .back {
        font-size: 20px;
    }

    .card > .front img {
        width: 30px;
    }

    .card > .back > img {
        width: 30px;
    }

    .card > .front,
    .card > .back {
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;
        border: solid 2px #FFFFFF;
    }

    code {
        font-size: 14px;
    }

}